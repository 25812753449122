@import '../../../../scss/theme-bootstrap';

.button-links-formatter {
  text-align: center;
  &__header {
    margin-bottom: 20px;
  }
  &__links {
    text-align: $ldirection;
    margin-top: 45px;
    @include breakpoint($landscape-up) {
      text-align: center;
      margin-top: 25px;
    }
  }
  &__carousel-wrapper {
    position: relative;
    margin: 45px auto;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  &__carousel {
    width: 95%;
    margin: 25px auto;
    &:focus {
      outline: none;
    }
  }
  &__carousel-controls {
    .slick-arrow {
      min-height: 50px;
      min-width: 40px;
      padding: 10px;
    }
    .slick-next {
      padding-#{$rdirection}: 0;
    }
    .slick-prev {
      padding-#{$ldirection}: 0;
    }
  }
  &__carousel-item {
    display: inline-block;
    .button-link-pc {
      a {
        @include title--h6;
        font-family: $font--lars;
        text-transform: uppercase;
        height: 40px;
        padding: 0 24px;
        font-size: 12px;
        line-height: 40px;
        box-shadow: inset 0 0 0 1px $color-cream-stroke;
      }
    }
    &:focus {
      outline: none;
    }
    &.slick-slide {
      display: block;
      .button-link-mobile {
        a {
          @include title--h6;
          line-height: 48px;
          box-shadow: inset 0 0 0 1px $color-cream-stroke;
        }
        display: block;
        text-align: center;
        margin: 0;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
  }
  &--align-center {
    text-align: center;
  }
  &--align-#{$ldirection} {
    text-align: $ldirection;
  }
  &--align-#{$rdirection} {
    text-align: $rdirection;
  }
  &--mobile-align-center {
    @include breakpoint($landscape-down) {
      text-align: center;
    }
  }
  &--mobile-align-#{$ldirection} {
    @include breakpoint($landscape-down) {
      text-align: $ldirection;
    }
  }
  &--mobile-align-#{$rdirection} {
    @include breakpoint($landscape-down) {
      text-align: $rdirection;
    }
  }
}
